import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { LoadingSkeleton } from '@townsquare/loading-skeleton';
import { MainColumn, AsideColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { UpdateListSkeleton, FeedTitleSkeleton } from '../components/Skeletons';
import { MetaActionsWrapper } from '../components/Skeletons/styles';
import { SidebarContainer } from '../components/UpdatesFeeds/styles';

export const MetaActionsSkeleton = () => (
  <MetaActionsWrapper>
    <LoadingSkeleton width="90px" height="32px" />
    <LoadingSkeleton width="66px" height="32px" />
    <LoadingSkeleton width="100px" height="20px" />
  </MetaActionsWrapper>
);

export const TagUpdatesViewSkeleton = () => {
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const mainContent = (
    <>
      <FeedTitleSkeleton />
      <UpdateListSkeleton />
    </>
  );

  if (isNavRefreshEnabled) {
    return mainContent;
  }

  return (
    <>
      <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>{mainContent}</MainColumn>
      <AsideColumn>
        <SidebarContainer>
          <MetaActionsSkeleton />
          <SidebarSectionSkeleton rows={3} />
          <SidebarSectionSkeleton rows={3} />
        </SidebarContainer>
      </AsideColumn>
    </>
  );
};
