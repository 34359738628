import { validate } from 'uuid';

const CHART_CDN_DOMAIN_PREFIX = 'avp-chartio-castle-chartcdn';

export const avpUrlFilterAndSanitise = (url: string, urlCountMap: Record<string, number>) => {
  const { hostname, pathname } = new URL(url);
  if (hostname.startsWith(CHART_CDN_DOMAIN_PREFIX)) {
    urlCountMap[CHART_CDN_DOMAIN_PREFIX] = (urlCountMap[CHART_CDN_DOMAIN_PREFIX] ?? 0) + 1;
    return `${CHART_CDN_DOMAIN_PREFIX}_${urlCountMap[CHART_CDN_DOMAIN_PREFIX]}`;
  }
  // We currently only want to track AVP calls
  if (!pathname.startsWith('/gateway/api/avp/embed/workspaceId/')) {
    return null;
  }

  const pathNamePrefixRemoved = pathname.replace(/\/gateway\/api\/avp\/embed\/workspaceId\/.+?\//, '');
  const pathNameSanitised = pathNamePrefixRemoved
    .split('/')
    .map(path => (validate(path) ? '<uuid>' : path))
    .join('/');

  urlCountMap[pathNameSanitised] = (urlCountMap[pathNameSanitised] ?? 0) + 1;
  return `${pathNameSanitised}_${urlCountMap[pathNameSanitised]}`;
};

export const avpXhrFilter = (url: string) =>
  url.includes('/gateway/api/avp/') || url.includes('avp-chartio-castle-chartcdn');
