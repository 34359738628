import { GiveKudosLauncher } from '@atlaskit/give-kudos';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { useFlagActions } from '@townsquare/flags';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import * as Styled from './GiveKudosButton.styled';

const config = getConfig();

interface GiveKudosButtonProps {
  analyticsSubject: string;
  size?: 'compact' | 'default';
}

export const GiveKudosButton = (props: GiveKudosButtonProps) => {
  const [workspace] = useWorkspaceStore();
  const [isGiveKudosOpen, setIsGiveKudosOpen] = useState(false);
  const { addFlag } = useFlagActions();
  const analytics = useAnalytics();

  return (
    <>
      <Styled.GiveKudosButton
        spacing={props.size || 'compact'}
        onClick={() => {
          void analytics.ui(props.analyticsSubject, 'clicked');
          setIsGiveKudosOpen(true);
        }}
      >
        <FormattedMessage
          id="townsquare.packages.kudos-on-feed.give-kudos-button-label"
          defaultMessage="Give kudos"
          description="Give kudos button label"
        />
        <Styled.KudosBlobAnimation />
      </Styled.GiveKudosButton>
      <GiveKudosLauncher
        isOpen={isGiveKudosOpen}
        onClose={() => setIsGiveKudosOpen(false)}
        analyticsSource="atlas"
        teamCentralBaseUrl={config.fullUrl}
        cloudId={workspace.cloudId || ''}
        addFlag={addFlag}
      />
    </>
  );
};
