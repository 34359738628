import { graphql, readInlineData } from 'react-relay';
import { createStore } from 'react-sweet-state';

import { default as timezoneHolder } from '@townsquare/date-utils/timezone-holder';

import { storeHomeWorkspace$key } from './__generated__/storeHomeWorkspace.graphql';
import { storeWorkspace$key } from './__generated__/storeWorkspace.graphql';
import { WorkspaceDetails } from './types';

// deepcode ignore HardcodedNonCryptoSecret: this is not a secret
export const WORKSPACE_CLOUDID_SESSION_STORAGE_KEY = 'team-central-cloud-id';

const homeWorkspaceFragment = graphql`
  fragment storeHomeWorkspace on Workspace @inline {
    id
    uuid
    name
    keyPrefix
    timezone
    cloudId
    cloudName
    organisationId
    goalScoringMode
    type
    userIsOrgAdmin
    activationId
  }
`;

const workspaceFragment = graphql`
  fragment storeWorkspace on WorkspaceByContextSuccessfulResponse @inline {
    workspace {
      id
      uuid
      name
      keyPrefix
      timezone
      cloudId
      cloudUrl
      cloudName
      organisationId
      goalScoringMode
      type
      # eslint-disable-next-line relay/unused-fields
      activationId
      userIsOrgAdmin
      userHasInvitePermission
      featureContext {
        # eslint-disable-next-line relay/unused-fields
        transactionAccountId
        # eslint-disable-next-line relay/unused-fields
        edition
        # eslint-disable-next-line relay/unused-fields
        features {
          goalScoringMode
          privateProjects
          customFieldsLimit
        }
        # eslint-disable-next-line relay/unused-fields
        entitlementId
      }

      aiConfig {
        # eslint-disable-next-line relay/unused-fields
        enabled
      }
    }
    workspaceCount
  }
`;

const trySetPreferredWorkspace = (userId: string, cloudId = '') => {
  try {
    sessionStorage?.setItem(`${WORKSPACE_CLOUDID_SESSION_STORAGE_KEY}-${userId}`, cloudId);
  } catch {
    // Skip if we can't do this (session storage not available)
  }
};

export const workspaceStore = createStore({
  initialState: {} as WorkspaceDetails,
  actions: {
    initWorkspaceFromGraph:
      (workspaceRef: storeWorkspace$key, userId: string) =>
      ({ setState }) => {
        const data = readInlineData(workspaceFragment, workspaceRef);
        const workspace = data?.workspace;

        if (!workspace) {
          return;
        }

        const newState: WorkspaceDetails = {
          ...workspace,
          globalId: workspace.id,
          UUID: workspace.uuid,
          numOfActiveWorkspaces: data.workspaceCount ?? 1,
          cloudUrl: workspace.cloudUrl ?? '',
          cloudName: workspace.cloudName ?? '',
          edition: workspace.featureContext?.edition ?? 'FREE',
          featureContext: workspace.featureContext ?? undefined,
          capabilities: {
            orgAdmin: workspace.userIsOrgAdmin,
            inviteToWorkspace: workspace.userHasInvitePermission ?? false,
          },
          aiConfig: workspace.aiConfig,
          identifier: workspace.keyPrefix,
          type: workspace.type,
        };
        timezoneHolder.setWorkspaceTimezone(workspace.timezone);
        setState(newState);
        trySetPreferredWorkspace(userId, workspace.cloudId);
        return newState;
      },
    initWorkspaceFromHome:
      (workspaceRef: storeHomeWorkspace$key, numWorkspaces: number) =>
      ({ setState }) => {
        const workspace = readInlineData(homeWorkspaceFragment, workspaceRef);
        if (!workspace) {
          return;
        }

        const newState: WorkspaceDetails = {
          ...workspace,
          globalId: workspace.id,
          UUID: workspace.uuid,
          activationId: workspace.activationId,
          identifier: workspace.keyPrefix,
          numOfActiveWorkspaces: numWorkspaces,
          capabilities: {
            orgAdmin: workspace.userIsOrgAdmin,
            inviteToWorkspace: false,
          },
        };
        timezoneHolder.setWorkspaceTimezone(workspace.timezone);
        setState(newState);

        return newState;
      },
    initWorkspace:
      ({
        globalId,
        name,
        UUID,
        keyPrefix,
        timezone,
        cloudId,
        cloudUrl,
        organisationId,
        goalScoringMode,
        numOfActiveWorkspaces,
        featureContext,
        capabilities,
        aiConfig,
        type,
        identifier,
      }: WorkspaceDetails) =>
      ({ setState }) => {
        const newState = {
          globalId,
          UUID,
          name,
          keyPrefix,
          timezone,
          cloudId,
          cloudUrl,
          organisationId,
          goalScoringMode,
          numOfActiveWorkspaces,
          edition: featureContext?.edition ?? 'FREE',
          featureContext,
          capabilities,
          aiConfig,
          type,
          identifier,
        };
        timezoneHolder.setWorkspaceTimezone(timezone);
        setState(newState);
        return newState;
      },
    setWorkspaceTimezone:
      timezone =>
      async ({ setState }) => {
        timezoneHolder.setWorkspaceTimezone(timezone);
        setState({ timezone });
      },
    setWorkspaceGoalScoringMode:
      goalScoringMode =>
      async ({ setState }) => {
        setState({ goalScoringMode });
      },
    setJoinableWorkspaceIdentifier:
      joinableWorkspaceIdentifier =>
      ({ setState }) => {
        setState({ joinableWorkspaceIdentifier });
      },
  },
  name: 'workspace-store',
});
