import { MemoryReactionsStore, ReactionsStore, ReactionServiceClient } from '@atlaskit/reactions';

import { getConfig } from '@townsquare/config';
import { useTownsquareUrl } from '@townsquare/hooks';

export class CachedReactionServiceClient extends ReactionServiceClient {
  private reactionClientCache = new Map();

  /**
   * Reactions fires this event on any hover event, ends up spamming the endpoint with requests
   * Adding a light memory cache to prevent excessive requests
   */
  getDetailedReaction(containerAri: string, ari: string, emojiId: string) {
    const cacheKey = `${containerAri}:${ari}:${emojiId}`;
    if (this.reactionClientCache.has(cacheKey)) {
      return Promise.resolve(this.reactionClientCache.get(cacheKey));
    }
    return super.getDetailedReaction(containerAri, ari, emojiId).then(summary => {
      this.reactionClientCache.set(cacheKey, summary);
      return summary;
    });
  }

  addReaction(containerAri: string, ari: string, emojiId: string) {
    this.reactionClientCache.delete(`${containerAri}:${ari}:${emojiId}`);
    return super.addReaction(containerAri, ari, emojiId);
  }

  deleteReaction(containerAri: string, ari: string, emojiId: string) {
    this.reactionClientCache.delete(`${containerAri}:${ari}:${emojiId}`);
    return super.deleteReaction(containerAri, ari, emojiId);
  }
}

const config = getConfig();

let reactionClient: CachedReactionServiceClient | undefined;
let reactionStore: ReactionsStore | undefined;

const getReactionsClient = (baseUrl: string): CachedReactionServiceClient => {
  if (!reactionClient) {
    reactionClient = new CachedReactionServiceClient(baseUrl);
  }
  return reactionClient;
};

const getReactionsStore = (reactionClient: CachedReactionServiceClient): ReactionsStore => {
  if (!reactionStore) {
    reactionStore = new MemoryReactionsStore(reactionClient);
  }
  return reactionStore;
};

export const useReactionStore: () => ReactionsStore = () => {
  const reactionsUrl = useTownsquareUrl(config.reactionsPath);

  const reactionClient = getReactionsClient(reactionsUrl);
  return getReactionsStore(reactionClient);
};
