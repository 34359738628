import { Inline } from '@atlaskit/primitives';
import { TeamProfileCardTrigger } from '@atlaskit/profilecard';
import Spinner from '@atlaskit/spinner';
import { VerifiedTeamIcon } from '@atlassian/people-teams-ui/verified-team-icon';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { ConditionalWrapper } from '@townsquare/conditional-wrapper';
import { getConfig } from '@townsquare/config';
import { EllipsisTooltip } from '@townsquare/ellipsis';
import { useFlagActions } from '@townsquare/flags';
import { getProfileClient } from '@townsquare/profile-card-provider';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { Link, useGeneratePath, useRouterActions } from '@townsquare/router/primitives';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { TeamIcon, TeamIconRefresh } from '@townsquare/team-icon';
import { UserAvatarStyle } from '@townsquare/user-avatar';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { TeamCollapsedContext } from '../TeamCollapsedContext';
import { BaseTeamAvatarProps } from '../types';

import * as styled from './styles';

export const BaseTeamAvatar = (props: BaseTeamAvatarProps) => {
  const {
    teamId,
    size = 'medium',
    showName = true,
    hideBorder,
    name,
    linkToProfile = true,
    isBold,
    isLoading,
    byLine,
    customIcon,
    isVerified,
  } = props;
  const analytics = useAnalytics();
  const intl = useIntl();
  const [{ route }] = useRouter();
  const [{ organisationId }] = useWorkspaceStore();
  const { pushTo } = useRouterActions();
  const config = getConfig();
  const { addFlag } = useFlagActions();
  const [workspace] = useWorkspaceStore();
  const { isTeamCollapsed } = useContext(TeamCollapsedContext);
  const teamCollapsedContextExists = isTeamCollapsed !== undefined;
  const path = useGeneratePath(teamProfileRoute, { id: teamId });
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const onTeamAvatarLinkClick = () => analytics.ui('teamAvatarLink', 'clicked', { screen: route.name });
  const viewProfileOnClick = () => {
    void analytics.ui('viewTeamProfile', 'clicked', { route: route.name, cardContainer: 'teamAvatar' });
    if (window.self !== window.top) {
      window.open(`${config.fullUrl}${path}`, '_blank', 'noreferrer');
    } else {
      void pushTo(teamProfileRoute, { params: { id: teamId } });
    }
  };

  // Using non-null assertions below had the potential to break the app. Bandaid fix for now.
  if (!name || !teamId) return null;

  return (
    <styled.TeamAvatar>
      {isLoading ? (
        <styled.Spinner>
          <Spinner size="small" />
        </styled.Spinner>
      ) : (
        <ConditionalWrapper
          condition={teamCollapsedContextExists}
          wrapper={children => (
            <EllipsisTooltip
              content={
                isTeamCollapsed
                  ? intl.formatMessage({
                      id: 'townsquare.team-avatar.base-team-avatar.expand-to-see-contributors-tooltip',
                      description: 'Tooltip for expand to see contributors',
                      defaultMessage: 'Expand to show contributors',
                    })
                  : intl.formatMessage({
                      id: 'townsquare.team-avatar.base-team-avatar.collapse-list-of-contributors-tooltip',
                      description: 'Tooltip for collapse to see contributors',
                      defaultMessage: 'Collapse list of contributors',
                    })
              }
              position="left"
            >
              {children}
            </EllipsisTooltip>
          )}
        >
          <UserAvatarStyle.BorderlessAvatar size={size} hideBorder={hideBorder}>
            {isNavRefreshEnabled ? (
              <TeamIconRefresh teamName={name} />
            ) : (
              <TeamIcon size={size} teamName={name} customIcon={customIcon} />
            )}
          </UserAvatarStyle.BorderlessAvatar>
        </ConditionalWrapper>
      )}
      {showName && (
        <UserAvatarStyle.NameAndDescription size={size}>
          <TeamProfileCardTrigger
            orgId={organisationId}
            teamId={teamId}
            viewProfileOnClick={viewProfileOnClick}
            resourceClient={getProfileClient(workspace.cloudId)}
            addFlag={addFlag}
            cloudId={workspace.cloudId}
          >
            <ConditionalWrapper
              condition={linkToProfile && !isLoading}
              wrapper={children => (
                <Link to={teamProfileRoute} params={{ id: teamId }} onClick={onTeamAvatarLinkClick}>
                  {children}
                </Link>
              )}
            >
              <>
                {isGateEnabled('verified_teams_indicator_atlas') ? (
                  <Inline alignBlock="center">
                    <UserAvatarStyle.Name size={size} isBold={isBold}>
                      {name}
                    </UserAvatarStyle.Name>
                    {isVerified && <VerifiedTeamIcon size="small" />}
                  </Inline>
                ) : (
                  <UserAvatarStyle.Name size={size} isBold={isBold}>
                    {name}
                  </UserAvatarStyle.Name>
                )}
              </>
            </ConditionalWrapper>
          </TeamProfileCardTrigger>
          {byLine}
        </UserAvatarStyle.NameAndDescription>
      )}
    </styled.TeamAvatar>
  );
};
