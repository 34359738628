import { useCallback } from 'react';
import { Action, createActionsHook, createHook, createStore } from 'react-sweet-state';

import { useAnalytics } from '@townsquare/analytics';

export type ModalType =
  | 'archive-goal-modal'
  | 'clone-goal-modal'
  | 'clone-project-modal'
  | 'create-goal-modal'
  | 'create-directory-view-modal'
  | 'rename-directory-view-modal'
  | 'delete-directory-view-modal'
  | 'edit-directory-view-modal'
  | 'delete-update-modal'
  | 'examples-modal'
  | 'remove-team-modal'
  | 'remove-user-access-modal'
  | 'update-modal'
  | 'goal-update-modal'
  | 'feedback-modal'
  | 'give-kudos-modal'
  | 'connect-project-to-jira-modal'
  | 'viewers-modal'
  | 'warning-modal'
  | 'add-people-benefits-modal'
  | 'progress-metric_create-metric-modal'
  | 'progress-metric_edit-metric-modal'
  | 'progress-metric_delete-metric-modal'
  | 'progress-metric_remove-target-modal'
  | 'progress-metric_metric-settings-modal'
  | 'platform-create-goal-modal';

export type Modal<T extends ModalType> = {
  component?: JSX.Element;
  type: T;
};

type ModalState = {
  modal: Modal<ModalType> | undefined;
  // Support for multiple modals, only one is shown at a time, but it will open the last one when closed if reopenLastModal is true
  stack: Modal<ModalType>[];
};

const actions = {
  openModal:
    <T extends ModalType>(
      type: T,
      { reopenLastModal, component }: { reopenLastModal?: boolean; component?: JSX.Element } = {},
    ): Action<ModalState> =>
    ({ getState, setState }) => {
      const { modal } = getState();
      setState({
        modal: {
          type,
          component,
        },
        /**
         * If reopenLastModal is true, we add the previous modal to the stack
         */
        stack: reopenLastModal && modal ? [...getState().stack, modal] : getState().stack,
      });
    },
  closeModal:
    (target: ModalType): Action<ModalState> =>
    ({ setState, getState }) => {
      const { modal, stack } = getState();
      if (modal?.type === target) {
        /**
         * If there are any modals in the stack, we pop the last one and set it as the current modal,
         * otherwise lastModal will be undefined and the modal will be closed
         */
        const lastModal = stack.pop();
        setState({
          modal: lastModal,
          stack: stack,
        });
      }
    },
} as const;

const modalStore = createStore<ModalState, typeof actions>({
  initialState: {
    modal: undefined,
    stack: [],
  },
  actions,
  name: 'modal-store',
});

export const useModalStore = createHook(modalStore, { selector: s => s.modal });
const useModalActionsInner = createActionsHook(modalStore);

export const useModalActions = () => {
  const actions = useModalActionsInner();
  const analytics = useAnalytics();

  const openModalWithAnalytics = useCallback(
    (...args: Parameters<typeof actions.openModal>) => {
      actions.openModal(...args);
      void analytics.track('modal', 'open', undefined, args[0]);
    },
    [actions, analytics],
  );

  return {
    ...actions,
    openModal: openModalWithAnalytics,
  };
};
