import Button from '@atlaskit/button';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { IconBeforeMarginFix } from '@townsquare/icons/margin-fix';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { FILTER_OPTIONS_BUTTON_STYLE, REFRESHED_FILTER_OPTIONS_BUTTON_STYLE } from './style';

type FilterOptionButtonProps = {
  onClick?: () => void;
  testId?: string;
  iconBefore?: React.ReactChild;
  title: string;
  isDisabled?: boolean;
};
export function FilterOptionButton({ onClick, testId, iconBefore, title, isDisabled }: FilterOptionButtonProps) {
  const homeVisualRefreshEnabled = useIsHomeVisualRefreshEnabled();

  return (
    <Button
      isDisabled={isDisabled}
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
      style={homeVisualRefreshEnabled ? REFRESHED_FILTER_OPTIONS_BUTTON_STYLE : FILTER_OPTIONS_BUTTON_STYLE}
      onClick={onClick}
      iconBefore={<IconBeforeMarginFix>{iconBefore}</IconBeforeMarginFix>}
      testId={testId}
    >
      <span style={{ margin: `0 ${token('space.050')}` }}>{title}</span>
    </Button>
  );
}
