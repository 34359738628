/**
 * @generated SignedSource<<16a3ff75854db7bd0f58c0bda28993f1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DirectoryActiveViewQuery$variables = {
  containerId?: string | null;
  directoryViewUuid: string;
  isDirectoryViewUuidPresent: boolean;
  workspaceUuid?: string | null;
};
export type DirectoryActiveViewQuery$data = {
  readonly directoryViewByUuid?: {
    readonly " $fragmentSpreads": FragmentRefs<"storeDirectoryView">;
  } | null;
};
export type DirectoryActiveViewQuery = {
  response: DirectoryActiveViewQuery$data;
  variables: DirectoryActiveViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDirectoryViewUuidPresent"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v4 = [
  {
    "kind": "Variable",
    "name": "containerId",
    "variableName": "containerId"
  },
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "directoryViewUuid"
  },
  {
    "kind": "Variable",
    "name": "workspaceUuid",
    "variableName": "workspaceUuid"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "uuid"
},
v7 = {
  "kind": "ScalarField",
  "name": "name"
},
v8 = {
  "kind": "ScalarField",
  "name": "storedTql"
},
v9 = {
  "kind": "ScalarField",
  "name": "entityType"
},
v10 = {
  "concreteType": "DirectoryViewSort",
  "kind": "LinkedField",
  "name": "sort",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "projectSort"
    },
    {
      "kind": "ScalarField",
      "name": "goalSort"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "uiViewType"
},
v12 = {
  "concreteType": "DirectoryViewUi",
  "kind": "LinkedField",
  "name": "uiMetadata",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "timelineDateSpan"
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v14 = {
  "kind": "ScalarField",
  "name": "favouriteCount"
},
v15 = {
  "kind": "ScalarField",
  "name": "aaid"
},
v16 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v17 = {
  "concreteType": "UserPII",
  "kind": "LinkedField",
  "name": "pii",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "kind": "ScalarField",
      "name": "picture"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "DirectoryActiveViewQuery",
    "selections": [
      {
        "condition": "isDirectoryViewUuidPresent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "DirectoryView",
            "kind": "LinkedField",
            "name": "directoryViewByUuid",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "storeDirectoryView",
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "creator",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "directoryViewColumns",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v16/*: any*/),
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ],
                        "type": "StandardDirectoryViewColumn"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v16/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "type": "CustomDirectoryViewColumn"
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryFavoriteViewButton"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "DirectoryManageViewDialog"
                  }
                ],
                "argumentDefinitions": []
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "DirectoryActiveViewQuery",
    "selections": [
      {
        "condition": "isDirectoryViewUuidPresent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v4/*: any*/),
            "concreteType": "DirectoryView",
            "kind": "LinkedField",
            "name": "directoryViewByUuid",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v5/*: any*/),
                  (v17/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "name": "directoryViewColumns",
                "plural": true,
                "selections": [
                  (v16/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      (v7/*: any*/)
                    ],
                    "type": "StandardDirectoryViewColumn"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "type": "CustomDirectoryViewColumn"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ]
              },
              {
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "lastEditedBy",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "creationDate"
              },
              {
                "kind": "ScalarField",
                "name": "lastEditedDate"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "05f48c20a92d3e4bd7cde61e9acdbc09",
    "id": null,
    "metadata": {},
    "name": "DirectoryActiveViewQuery",
    "operationKind": "query",
    "text": "query DirectoryActiveViewQuery(\n  $workspaceUuid: UUID\n  $containerId: String\n  $directoryViewUuid: UUID!\n  $isDirectoryViewUuidPresent: Boolean!\n) {\n  directoryViewByUuid(workspaceUuid: $workspaceUuid, containerId: $containerId, uuid: $directoryViewUuid) @include(if: $isDirectoryViewUuidPresent) {\n    ...storeDirectoryView\n    id\n  }\n}\n\nfragment DeleteDirectoryViewModal on DirectoryView {\n  name\n  uuid\n  isFavourite\n  favouriteCount\n}\n\nfragment DirectoryFavoriteViewButton on DirectoryView {\n  id\n  uuid\n  name\n  isFavourite\n  entityType\n  ...DirectoryManageViewDialog\n}\n\nfragment DirectoryManageViewDialog on DirectoryView {\n  entityType\n  isFavourite\n  creator {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  lastEditedBy {\n    aaid\n    pii {\n      name\n      picture\n    }\n    id\n  }\n  creationDate\n  lastEditedDate\n  ...RenameDirectoryViewModal\n  ...DeleteDirectoryViewModal\n}\n\nfragment RenameDirectoryViewModal on DirectoryView {\n  name\n  uuid\n}\n\nfragment storeDirectoryView on DirectoryView {\n  id\n  uuid\n  name\n  storedTql\n  entityType\n  sort {\n    projectSort\n    goalSort\n  }\n  uiViewType\n  uiMetadata {\n    timelineDateSpan\n  }\n  isFavourite\n  favouriteCount\n  creator {\n    aaid\n    id\n  }\n  directoryViewColumns {\n    __typename\n    ... on StandardDirectoryViewColumn {\n      __typename\n      id\n      name\n    }\n    ... on CustomDirectoryViewColumn {\n      __typename\n      id\n      uuid\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  ...DirectoryFavoriteViewButton\n  ...DirectoryManageViewDialog\n}\n"
  }
};
})();

(node as any).hash = "3b75c9c6bd6386302425c7eeee01d103";

export default node;
