import React from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
};

/**
 * Render a component with a conditionally rendered wrapping element.
 * Adapted from https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
 *
 * @param condition whether or not the wrapping element is rendered
 * @param wrapper render prop used to render the wrapping element
 * @param children the main component
 * @constructor
 */
export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : <>{children}</>;
