import { Team } from '@atlaskit/smart-user-picker';
import React from 'react';

import { BaseTeamAvatar } from '@townsquare/team-avatar';

export function TeamOption({ team }: { team: Team }) {
  return (
    <BaseTeamAvatar
      linkToProfile={false}
      size="small"
      key={team.id}
      name={team.name}
      teamId={team.id}
      isVerified={team.verified}
    />
  );
}
