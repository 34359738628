import Button from '@atlaskit/button';
import { Inline, Stack } from '@atlaskit/primitives';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl-next';
import { usePathParam } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useCreateTeamModal } from '@townsquare/create-team-modal';
import { useInviteUserModal } from '@townsquare/invite-user-modal';
import { GiveKudosButton } from '@townsquare/kudos-on-feed';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { useQueryParams } from '@townsquare/router';
import { useRouterActions } from '@townsquare/router/primitives';
import { SortButton, SortButtonProps } from '@townsquare/sort-button';
import { useMetaClickHandler } from '@townsquare/ui-interactions';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { staffDirectoryRoute } from '../../route';
import { ScreenType } from '../ScreenType';
import { SearchBar } from '../SearchBar';
import { SearchBarProps } from '../SearchBar/types';
import { DirectoryTeamSortEnum } from '../__generated__/StaffDirectoryViewQuery.graphql';

import * as styled from './styles';

const CreateTeamButton = ({
  isDisabled = false,
  onCreateTeamClicked,
}: {
  isDisabled?: boolean;
  onCreateTeamClicked?: () => void;
}) => (
  <Button isDisabled={isDisabled} id="create-team-button" onClick={onCreateTeamClicked}>
    <FormattedMessage
      id="townsquare.web.directory-header.create-team-button"
      description="Create team button"
      defaultMessage="Create team"
    />
  </Button>
);

const InviteUserButton = ({
  isDisabled = false,
  onInviteUserClicked,
}: {
  isDisabled?: boolean;
  onInviteUserClicked?: () => void;
}) => (
  <Button appearance="primary" isDisabled={isDisabled} onClick={onInviteUserClicked}>
    <FormattedMessage
      id="townsquare.web.directory-header.invite-user-button"
      description="Invite user button"
      defaultMessage="Add people"
    />
  </Button>
);

const NAMED_SCREENS = [
  ScreenType.SEARCH_TEAMS,
  ScreenType.BROWSE_LOCATIONS,
  ScreenType.BROWSE_DEPARTMENTS,
  ScreenType.BROWSE_JOB_TITLES,
  ScreenType.SEARCH_PEOPLE,
  ScreenType.SEARCH_ALL,
  ScreenType.BROWSE_KUDOS,
];

const nameForScreen = (intl: IntlShape, screen: ScreenType): string => {
  switch (screen) {
    case ScreenType.SEARCH_ALL:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.search-all',
        description: 'Search all screen',
        defaultMessage: 'People and teams',
      });
    case ScreenType.SEARCH_TEAMS:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.search-teams',
        description: 'Search teams screen',
        defaultMessage: 'Teams',
      });
    case ScreenType.BROWSE_LOCATIONS:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.browse-locations',
        description: 'Browse locations screen',
        defaultMessage: 'Locations',
      });
    case ScreenType.BROWSE_KUDOS:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.browse-kudos',
        description: 'Browse kudos screen',
        defaultMessage: 'Kudos',
      });
    case ScreenType.BROWSE_DEPARTMENTS:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.browse-departments',
        description: 'Browse departments screen',
        defaultMessage: 'Departments',
      });
    case ScreenType.BROWSE_JOB_TITLES:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.browse-job-titles',
        description: 'Browse job titles screen',
        defaultMessage: 'Job titles',
      });
    case ScreenType.SEARCH_PEOPLE:
    default:
      return intl.formatMessage({
        id: 'townsquare.web.directory-header.search-people',
        description: 'Search people screen',
        defaultMessage: 'People',
      });
  }
};

export const DirectoryHeader = ({
  loading = false,
  screen,
  searchBarProps,
  showSearchBar,
  sortProps,
}: {
  loading?: boolean;
  screen: ScreenType;
  searchBarProps: Omit<SearchBarProps, 'loading'>;
  showSearchBar: boolean;
  sortProps?: SortButtonProps<DirectoryTeamSortEnum>;
}) => {
  const [{ name, capabilities }] = useWorkspaceStore();
  const query = useQueryParams();
  const [search] = usePathParam('search');
  const { pushTo } = useRouterActions();
  const analytics = useAnalytics();
  const intl = useIntl();

  const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal({
    analyticsCtx: { source: 'staffDirectory' },
    onCreate: team => pushTo(teamProfileRoute, { params: { id: team.id } }),
  });
  const [inviteUserModal, inviteUserModalTrigger] = useInviteUserModal({
    analyticsCtx: { source: 'staffDirectory' },
  });

  // Should not have TQL in browse screen since it's not searchable
  const { tql: _tqlParam, ...existingQueryParams } = query;

  const queryParams = createSearchParamsFromScratch({
    ...existingQueryParams,
    screen: ScreenType[ScreenType.BROWSE_ALL],
  });
  const { handler } = useMetaClickHandler(
    staffDirectoryRoute,
    { params: { search }, query: Object.fromEntries(queryParams) },
    () => {
      analytics.ui('staffDirectoryHeadingBreadcrumb', 'clicked', { screen });
    },
  );

  const onBreadcrumbClicked = useMemo(() => handler, [handler]);

  const onCreateTeamClicked = useCallback(() => {
    void analytics.ui('staffDirectoryCreateTeam', 'clicked', {
      screen,
    });
    createTeamModalTrigger();
  }, [analytics, screen, createTeamModalTrigger]);

  const onInviteUserClicked = useCallback(() => {
    void analytics.ui('staffDirectoryInviteUser', 'clicked', {
      screen,
    });
    inviteUserModalTrigger();
  }, [analytics, screen, inviteUserModalTrigger]);

  const isNamedScreen = NAMED_SCREENS.includes(screen);

  return (
    <Stack space="space.300">
      <Inline spread="space-between" alignBlock="baseline">
        <div>
          <styled.DirectoryHeaderText
            isSubtle={isNamedScreen}
            isLink={isNamedScreen}
            onClick={isNamedScreen ? onBreadcrumbClicked : undefined}
          >
            {name}
          </styled.DirectoryHeaderText>
          {isNamedScreen && (
            <>
              {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
              <styled.DirectoryHeaderText isSubtle> / </styled.DirectoryHeaderText>
              <styled.DirectoryHeaderText>{nameForScreen(intl, screen)}</styled.DirectoryHeaderText>
            </>
          )}
        </div>

        <styled.DirectoryHeaderButtons>
          {screen !== ScreenType.SEARCH_PEOPLE && screen !== ScreenType.BROWSE_KUDOS && (
            <CreateTeamButton isDisabled={loading} onCreateTeamClicked={onCreateTeamClicked} />
          )}
          {screen !== ScreenType.SEARCH_TEAMS &&
            screen !== ScreenType.BROWSE_KUDOS &&
            capabilities?.inviteToWorkspace && (
              <InviteUserButton isDisabled={loading} onInviteUserClicked={onInviteUserClicked} />
            )}
          {screen === ScreenType.BROWSE_KUDOS && <GiveKudosButton analyticsSubject="staffDirectory" size="default" />}
        </styled.DirectoryHeaderButtons>
      </Inline>
      {createTeamModal}
      {inviteUserModal}
      {showSearchBar && (
        <SearchBar loading={loading} {...searchBarProps} shouldShowSearchBar={screen != ScreenType.BROWSE_KUDOS} />
      )}
      {showSearchBar && sortProps && (
        <styled.SortRow>
          <SortButton<DirectoryTeamSortEnum> isDisabled={loading} {...sortProps} />
        </styled.SortRow>
      )}
    </Stack>
  );
};
