/**
 * @generated SignedSource<<83478c74d7cbb3a6810ce7fd7d745ef5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccessControlCapability = "ACCESS" | "ADMINISTER" | "CREATE";
export type CapabilityContainer = "FOCUS_AREAS_APP" | "GOALS_APP" | "PROJECTS_APP";
import type { FragmentRefs } from "relay-runtime";
export type utils_checkUserHasCapabilities$data = {
  readonly currentUserAppCapabilities: {
    readonly capabilities: ReadonlyArray<{
      readonly capability: AccessControlCapability | null;
      readonly capabilityContainer: CapabilityContainer | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "utils_checkUserHasCapabilities";
};
export type utils_checkUserHasCapabilities$key = {
  readonly " $data"?: utils_checkUserHasCapabilities$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_checkUserHasCapabilities">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utils_checkUserHasCapabilities"
};

(node as any).hash = "7d4c45ba6c56843e62e760caf2d707c7";

export default node;
