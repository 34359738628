import AlertIcon from '@atlaskit/icon/core/alert';
import DecisionIcon from '@atlaskit/icon/core/decision';
import LightbulbIcon from '@atlaskit/icon/core/lightbulb';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { useIntl } from 'react-intl-next';

import { DecisionIcon as DecisionIconLegacy } from './DecisionIcon';
import { LightbulbIcon as LightbulbIconLegacy } from './LightbulbIcon';
import { SirenIcon } from './SirenIcon';

type LearningType = 'LEARNING' | 'RISK' | 'DECISION';

export const HighlightIcon = (props: { type: LearningType | null | undefined }) => {
  const { type } = props;
  const intl = useIntl();

  switch (type) {
    case 'LEARNING':
      return (
        <LightbulbIcon
          label={intl.formatMessage({
            id: 'townsquare.packages.icons.learning',
            defaultMessage: 'Lightbulb icon',
            description: 'Lightbulb icon',
          })}
          LEGACY_fallbackIcon={LightbulbIconLegacy}
          spacing="spacious"
          color={token('color.icon.warning')}
        />
      );
    case 'RISK':
      return (
        <AlertIcon
          LEGACY_fallbackIcon={SirenIcon}
          label={intl.formatMessage({
            id: 'townsquare.packages.icons.risk',
            defaultMessage: 'Risk icon',
            description: 'Risk icon',
          })}
          color={token('color.icon.danger')}
          spacing="spacious"
        />
      );
    case 'DECISION':
    default:
      return (
        <DecisionIcon
          label={intl.formatMessage({
            id: 'townsquare.packages.icons.decision',
            defaultMessage: 'Decision icon',
            description: 'Decision icon',
          })}
          LEGACY_fallbackIcon={DecisionIconLegacy}
          spacing="spacious"
          color={token('color.icon.success')}
        />
      );
  }
};
