/**
 * @generated SignedSource<<380cda96c9a7a84887b0debe6546adb9>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type GoalSortEnum = "CREATION_DATE_ASC" | "CREATION_DATE_DESC" | "HIERARCHY_ASC" | "HIERARCHY_DESC" | "HIERARCHY_LEVEL_ASC" | "HIERARCHY_LEVEL_DESC" | "ID_ASC" | "ID_DESC" | "LATEST_UPDATE_DATE_ASC" | "LATEST_UPDATE_DATE_DESC" | "NAME_ASC" | "NAME_DESC" | "PHASE_ID_ASC" | "PHASE_ID_DESC" | "PROJECT_COUNT_ASC" | "PROJECT_COUNT_DESC" | "SCORE_ASC" | "SCORE_DESC" | "START_DATE_ASC" | "START_DATE_DESC" | "TARGET_DATE_ASC" | "TARGET_DATE_DESC" | "WATCHER_COUNT_ASC" | "WATCHER_COUNT_DESC" | "WATCHING_ASC" | "WATCHING_DESC";
export type ExportGoalCsvQuery$variables = {
  after?: string | null;
  containerId?: string | null;
  directoryViewUuid?: string | null;
  first?: number | null;
  sorts?: ReadonlyArray<GoalSortEnum | null> | null;
  tql?: string | null;
  workspaceUuid?: string | null;
};
export type ExportGoalCsvQuery$data = {
  readonly goalTqlFullHierarchy: {
    readonly count: number;
  } | null;
};
export type ExportGoalCsvQuery = {
  response: ExportGoalCsvQuery$data;
  variables: ExportGoalCsvQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "directoryViewUuid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sorts"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tql"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceUuid"
},
v7 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "after",
        "variableName": "after"
      },
      {
        "kind": "Variable",
        "name": "containerId",
        "variableName": "containerId"
      },
      {
        "kind": "Variable",
        "name": "directoryViewUuid",
        "variableName": "directoryViewUuid"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "q",
        "variableName": "tql"
      },
      {
        "kind": "Variable",
        "name": "sorts",
        "variableName": "sorts"
      },
      {
        "kind": "Variable",
        "name": "workspaceUuid",
        "variableName": "workspaceUuid"
      }
    ],
    "concreteType": "GoalConnection",
    "kind": "LinkedField",
    "name": "goalTqlFullHierarchy",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "count"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ExportGoalCsvQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExportGoalCsvQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "52494795b5bee83721421a4d0394efe6",
    "id": null,
    "metadata": {},
    "name": "ExportGoalCsvQuery",
    "operationKind": "query",
    "text": "query ExportGoalCsvQuery(\n  $tql: String\n  $directoryViewUuid: UUID\n  $workspaceUuid: UUID\n  $containerId: String\n  $after: String\n  $first: Int\n  $sorts: [GoalSortEnum]\n) {\n  goalTqlFullHierarchy(first: $first, after: $after, q: $tql, directoryViewUuid: $directoryViewUuid, workspaceUuid: $workspaceUuid, containerId: $containerId, sorts: $sorts) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c3b2c1c040d4480181a7cbd9d4722ee";

export default node;
