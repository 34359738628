import LinkCreate, { CreatePayload } from '@atlaskit/link-create';
import { TownsquareGoalAri } from '@atlassian/ari';
import { createGoalLinkCreatePlugin, type CreateGoalPluginConfig } from '@atlassian/link-create-goal';
import React from 'react';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import {
  CapabilitySubject,
  ExperienceConfigPreset,
  generateErrorMetadata,
  useExperienceTracker,
} from '@townsquare/monitoring';
import { useUserStore } from '@townsquare/user-store';
import { useCPUSScope, useWorkspaceStore } from '@townsquare/workspace-store/hooks';

type PluginOptions = Omit<CreateGoalPluginConfig, 'triggeredFrom' | 'productKey' | 'defaultCloudId' | 'defaultOwner'>;
export type CreatedGoal = CreatePayload;

export type PlatformCreateGoalModalOptions = PluginOptions & {
  /*
   * The string to use as the triggeredFrom value for analytics. Prefixed to differentiate from platform experiences.
   */
  triggeredFrom: `townsquare-${string}`;

  /*
   * If we should move to the goal page after creating a goal.
   */
  redirectOnSave?: boolean;

  onCreateGoal?: (payload: CreatedGoal) => void;
};

export type PlatformCreateGoalModalProps = PlatformCreateGoalModalOptions & {
  onClose: () => void;
};

export const PlatformCreateGoalModal = ({
  triggeredFrom,
  onClose,
  parentGoalAri,
  redirectOnSave,
  onCreateGoal,
}: PlatformCreateGoalModalProps) => {
  const analytics = useAnalytics();
  const [routerStore, { push }] = useRouter();

  const [workspace] = useWorkspaceStore();
  const [user] = useUserStore();
  const cpusScope = useCPUSScope({ isInviteRelated: false });

  const goalCreatedExperienceTracker = useExperienceTracker(
    CapabilitySubject.GoalCreated,
    ExperienceConfigPreset.ExperienceInlineResult,
  );

  useOnMount(() => {
    void analytics.track('createGoalDialog', 'opened', {
      screen: routerStore.route.name,
      isSubGoal: Boolean(parentGoalAri),
    });
  });

  const plugins = [
    createGoalLinkCreatePlugin({
      defaultCloudId: workspace.cloudId,
      gatewayBaseUrl: undefined, // HOT-113609 - Set as undefined to match home.a.c behaviour
      orgId: workspace.organisationId,
      isOrgAdmin: workspace.capabilities?.orgAdmin,
      defaultOwner: {
        id: user.accountId,
        name: user.name,
        avatarUrl: user.picture,
      },
      productKey: cpusScope,
      hideSitePicker: true,
      parentGoalAri,
    }),
  ];

  const onCreate = (payload: CreatePayload) => {
    let analyticsAttributes;
    try {
      const goalUuid = TownsquareGoalAri.parse(payload.objectId).goalUuid;
      analyticsAttributes = {
        goalId: goalUuid,
      };
    } catch {
      analyticsAttributes = undefined;
    }

    void analytics.track(parentGoalAri ? 'sub-goal' : 'goal', 'created', analyticsAttributes);
    void analytics.ui('createGoalButton', 'clicked', { screen: routerStore.route.name });

    if (window.Intercom) {
      window.Intercom('trackEvent', 'engagedWithTC', {
        ...analyticsAttributes,
        interaction: parentGoalAri ? 'subGoalCreated' : 'goalCreated',
      });
    }

    if (redirectOnSave) {
      const goalPath = new URL(payload.url).pathname;
      void push(goalPath);
    }

    void goalCreatedExperienceTracker.success();
    onCreateGoal?.(payload);
    onClose();
  };

  const onFailure = (error: unknown): void => {
    void analytics.track('goal', 'create-failed', { screen: routerStore.route.name });

    if (error instanceof Error) {
      goalCreatedExperienceTracker.failure(generateErrorMetadata(error));
    } else {
      void goalCreatedExperienceTracker.failure({ metadata: { reason: 'unknown' } });
    }
  };

  const onCancel = () => {
    void analytics.ui('cancelGoalButton', 'clicked', {
      screen: routerStore.route.name,
    });
    goalCreatedExperienceTracker.abort();

    onClose();
  };

  return (
    <LinkCreate
      testId="link-create-goal-modal"
      entityKey="goal"
      plugins={plugins}
      triggeredFrom={triggeredFrom}
      onCreate={onCreate}
      onFailure={onFailure}
      onCancel={onCancel}
      active
    />
  );
};
