import { useEffect } from 'react';

import { useAppStateStore } from '@townsquare/app-state-store';
import { useWorkspaceStore } from '@townsquare/workspace-store';
import { useProductDisplayNameFull } from '@townsquare/workspace-store/hooks';

type Props = {
  title?: string;
};

const useProxy = typeof __USE_PROXY__ !== 'undefined' ? __USE_PROXY__ : false;

export const PageTitle = (props: Props) => {
  const [workspace] = useWorkspaceStore();
  const [{ appState }] = useAppStateStore();
  const productDisplayNameFull = useProductDisplayNameFull();
  const { title } = props;

  useEffect(() => {
    const workspaceName = workspace.globalId ? workspace.name : '';
    const browserTitle = title ? `${title} — ${workspaceName} — ${productDisplayNameFull}` : productDisplayNameFull;
    const proxyString = useProxy ? '(Using local proxy) ' : '';
    document.title = proxyString + browserTitle;
  }, [workspace, appState, title, productDisplayNameFull]);

  return null;
};
