import { graphql } from 'react-relay';
import { readInlineData } from 'relay-runtime';

import { filterNull } from '@townsquare/filter-type';
import { UserCapabilityType } from '@townsquare/home/router/types';
import { getRoleFromCapability } from '@townsquare/home/router/utils';

import { utils_checkUserHasCapabilities$key } from './__generated__/utils_checkUserHasCapabilities.graphql';

export const checkUserHasCapabilities = (
  data: utils_checkUserHasCapabilities$key | null | undefined,
  requirements: UserCapabilityType[],
): boolean => {
  const workspace = readInlineData<utils_checkUserHasCapabilities$key>(
    graphql`
      fragment utils_checkUserHasCapabilities on Workspace @inline {
        currentUserAppCapabilities {
          capabilities {
            capability
            capabilityContainer
          }
        }
      }
    `,
    data,
  );

  if (!workspace || !workspace.currentUserAppCapabilities || !workspace.currentUserAppCapabilities.capabilities) {
    return false;
  }

  const userCapabilities = workspace.currentUserAppCapabilities.capabilities
    .map(item => {
      if (!item || !item.capabilityContainer || !item.capability) {
        return null;
      }
      return getRoleFromCapability(item.capabilityContainer, item.capability);
    })
    .filter(filterNull);

  return requirements.every(capability => userCapabilities.includes(capability));
};
