import TeamsIcon from '@atlaskit/icon/core/teams';
import { token } from '@atlaskit/tokens';
import React from 'react';

import { getAvatarPlaceholderColor, teamNameToColorIndex } from '@townsquare/theme';

import type { Props } from '../types';

import { StyledTeamIcon } from './styles';

const UNKNOWN_TEAM_NAME = 'Unknown';

export const TeamIcon = ({ teamName, size, shape = 'square', customIcon }: Props) => {
  // TODO: TC-2245 - Temporary until using actual team icon component
  const color = getAvatarPlaceholderColor(teamName ?? UNKNOWN_TEAM_NAME);
  return (
    <StyledTeamIcon placeholderColor={color} size={size} shape={shape}>
      {customIcon ? customIcon : (teamName ?? UNKNOWN_TEAM_NAME)[0].toUpperCase()}
    </StyledTeamIcon>
  );
};

const iconColors = [
  token('color.icon.accent.purple'),
  token('color.icon.accent.teal'),
  token('color.icon.accent.green'),
  token('color.icon.accent.orange'),
  token('color.icon.accent.yellow'),
  token('color.icon.accent.red'),
  token('color.icon.accent.magenta'),
  token('color.icon.accent.blue'),
];

export const TeamIconRefresh = ({ teamName }: Pick<Props, 'teamName'>) => {
  const colorIndex = teamNameToColorIndex(teamName ?? UNKNOWN_TEAM_NAME);
  return <TeamsIcon color={iconColors[colorIndex]} label="" />;
};
