import { Anchor, Pressable, PressableProps, xcss } from '@atlaskit/primitives';
import React, { PropsWithChildren } from 'react';

import type { InlineLinkButtonProps } from '../types';

const linkStyles = xcss({
  padding: 'space.0',
  color: 'color.link',
  font: 'font.body',
  fontWeight: 'font.weight.medium',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
  ':active': {
    color: 'color.link.pressed',
  },
});

export const InlineLinkButton = ({ onClick, href, children }: PropsWithChildren<InlineLinkButtonProps>) => {
  if (href) {
    return (
      <Anchor xcss={linkStyles} href={href} onClick={onClick}>
        {children}
      </Anchor>
    );
  }

  return (
    <Pressable
      backgroundColor="color.background.neutral.subtle"
      xcss={linkStyles}
      onClick={onClick as PressableProps['onClick']}
    >
      {children}
    </Pressable>
  );
};
