import { AtlassianNavigation } from '@atlaskit/atlassian-navigation';
import { token } from '@atlaskit/tokens';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import {
  GOAL_DIRECTORY_PATH,
  GOAL_PATH_PATTERN,
  NAME_EMBED_SUFFIX,
  NAME_REDIRECT_SUFFIX,
  PROJECT_DIRECTORY_PATH,
  PROJECT_PATH_PATTERN,
  TEAM_PATH_PATTERN,
} from '@townsquare/config/routes';
import { useCreateGoalModal, useCreateGoalModalVersion } from '@townsquare/create-goal-modal';
import { useCreateProjectActions } from '@townsquare/create-project';
import { useCreateTeamModal } from '@townsquare/create-team-modal';
import { GiveFeedbackButton } from '@townsquare/feedback/button';
import { goalDirectoryRoute } from '@townsquare/goals-directory-view/route';
import { homeTabRoute } from '@townsquare/hometab-view';
import { useInviteUserModal } from '@townsquare/invite-user-modal';
import {
  CREATE_NEW_SHORTCUT,
  GO_GOALS_PAGE_SHORTCUT,
  GO_HOME_PAGE_SHORTCUT,
  GO_PROJECTS_PAGE_SHORTCUT,
  GO_TEAMS_PAGE_SHORTCUT,
  GO_TAGS_PAGE_SHORTCUT,
  getModifiedShortcut,
  useKeyboardShortcut,
} from '@townsquare/keyboard-shortcuts';
import { useKeyboardShortcutStore } from '@townsquare/keyboard-shortcuts';
import { projectDirectoryRoute } from '@townsquare/projects-directory-view/route';
import { teamProfileRoute } from '@townsquare/ptc-directory-view';
import { MOBILE_MODE_WIDTH_THRESHOLD } from '@townsquare/responsive-grid';
import { useRouterActions } from '@townsquare/router/primitives';
import { staffDirectoryRoute } from '@townsquare/staff-directory-view/route';
import { useFeatureGate } from '@townsquare/stat-sig/gate';
import { tagsDirectoryRoute } from '@townsquare/tags-directory-view/route';
import { useNoWorkspaceExperience } from '@townsquare/workspace-store';

import { AddPeople } from './AddPeople/AddPeople';
import { AppSwitcherButton } from './AppSwitcher/AppSwitcherButton';
import { CrossProductSearch } from './CrossProductSearch';
import { HelpPanel } from './Help';
import { Home } from './Home';
import { NewButton } from './NewButton/NewButton';
import { NoWorkspaceNavHelpButton } from './NoWorkspace/NoWorkspaceHelp';
import { NoWorkspaceSearch } from './NoWorkspace/NoWorkspaceSearch';
import { NotificationsBell } from './Notifications';
import { usePrimaryNavItems } from './PrimaryNavigationItems';
import { AccountPopup } from './Profile';
import { ProfileAndWorkspaceSwitcherButton } from './ProfileAndWorkspaceSwitcher/ProfileAndWorkspaceSwitcherButton';
import { ReleaseNote } from './ReleaseNote';
import { SettingsPopup } from './Settings';

const Navigation = () => {
  const intl = useIntl();
  const [routerState] = useRouter();
  const [isMobileWidth, setIsMobileWidth] = useState<boolean>(false);
  const noWorkspaceExperience = useNoWorkspaceExperience();
  const { openCreateProject } = useCreateProjectActions();
  const { openCreateGoalModal } = useCreateGoalModal(useCreateGoalModalVersion());
  const analytics = useAnalytics();
  const { pushTo } = useRouterActions();
  const [{ type }] = useKeyboardShortcutStore();

  const [createTeamModal, createTeamModalTrigger] = useCreateTeamModal({
    analyticsCtx: { source: 'teamsNav' },
    onCreate: team => pushTo(teamProfileRoute, { params: { id: team.id } }),
  });

  const [inviteUserModal, inviteUserModalTrigger] = useInviteUserModal({
    analyticsCtx: { source: 'teamsNav' },
  });

  const primaryItems = usePrimaryNavItems({
    triggers: { createTeamModalTrigger, inviteUserModalTrigger },
    isHome: false,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  // useRef prevents this from being recreated on each render
  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      if (entries[0].contentRect.width <= MOBILE_MODE_WIDTH_THRESHOLD) {
        setIsMobileWidth(true);
      } else {
        setIsMobileWidth(false);
      }
    }),
  );

  useEffect(() => {
    if (containerRef?.current) {
      resizeObserver.current.observe(containerRef.current);
    } else {
      resizeObserver.current.disconnect();
    }
  }, []);

  const renderNewButton = useCallback(
    () => (
      // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: token('space.150') }}>
        {noWorkspaceExperience ? null : <NewButton />}
        {!isMobileWidth && <GiveFeedbackButton />}
      </div>
    ),
    [noWorkspaceExperience, isMobileWidth],
  );

  const handleCreateNewKeypress = (e: KeyboardEvent) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'createNewShortcut');
    const path = routerState.route.path;
    if (path === PROJECT_DIRECTORY_PATH || path === PROJECT_PATH_PATTERN) {
      openCreateProject({ redirectOnSave: true });
    } else if (path === GOAL_DIRECTORY_PATH || path === GOAL_PATH_PATTERN) {
      openCreateGoalModal({
        triggeredFrom: 'townsquare-navigation',
      });
    } else if (path === TEAM_PATH_PATTERN) {
      createTeamModalTrigger();
    } else {
      document.getElementById('create-new-dropdown')?.click();
    }
  };

  const handleGoToKeypress = (_e: KeyboardEvent, keypress: string | undefined) => {
    switch (keypress) {
      case getModifiedShortcut(type, GO_HOME_PAGE_SHORTCUT):
        void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'goHomePageShortcut');
        void pushTo(homeTabRoute);
        break;
      case getModifiedShortcut(type, GO_PROJECTS_PAGE_SHORTCUT):
        void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'goProjectsPageShortcut');
        void pushTo(projectDirectoryRoute);
        break;
      case getModifiedShortcut(type, GO_GOALS_PAGE_SHORTCUT):
        void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'goGoalsPageShortcut');
        void pushTo(goalDirectoryRoute);
        break;
      case getModifiedShortcut(type, GO_TEAMS_PAGE_SHORTCUT):
        void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'goTeamsPageShortcut');
        void pushTo(staffDirectoryRoute);
        break;
      case getModifiedShortcut(type, GO_TAGS_PAGE_SHORTCUT):
        void analytics.ui('keyboardShortcut', 'pressed', { shortcutType: type }, 'goTagsPageShortcut');
        void pushTo(tagsDirectoryRoute);
        break;
    }
  };

  useKeyboardShortcut(CREATE_NEW_SHORTCUT, handleCreateNewKeypress);
  useKeyboardShortcut(GO_HOME_PAGE_SHORTCUT, handleGoToKeypress);
  useKeyboardShortcut(GO_PROJECTS_PAGE_SHORTCUT, handleGoToKeypress);
  useKeyboardShortcut(GO_GOALS_PAGE_SHORTCUT, handleGoToKeypress);
  useKeyboardShortcut(GO_TEAMS_PAGE_SHORTCUT, handleGoToKeypress);
  useKeyboardShortcut(GO_TAGS_PAGE_SHORTCUT, handleGoToKeypress);

  const ReleaseNotesAndHelp = useCallback(() => {
    if (noWorkspaceExperience) {
      return <NoWorkspaceNavHelpButton />;
    }
    return (
      // Hack to position release note icon in between search and help, based on previous
      // hack where we did it for settings (but help needs to be next to settings)
      <>
        <ReleaseNote />
        <HelpPanel isMobileWidth={isMobileWidth} />
      </>
    );
  }, [noWorkspaceExperience, isMobileWidth]);

  const isSplitBrainControlsEnabled = useFeatureGate('atlas_enable_split_brain_controls');
  const enableSiteSwitcher = !noWorkspaceExperience || isSplitBrainControlsEnabled;

  if (
    routerState.route.name === 'onboarding' ||
    routerState.route.name === 'noaccess' ||
    routerState.route.name === 'productOverview' ||
    routerState.route.name.endsWith(NAME_REDIRECT_SUFFIX) ||
    routerState.route.name.endsWith(NAME_EMBED_SUFFIX)
  ) {
    return null;
  }

  const hideIfOnMobile = (renderContents: undefined | (() => JSX.Element)) => {
    if (isMobileWidth) {
      return undefined;
    }

    return renderContents;
  };

  return (
    <div ref={containerRef}>
      <AtlassianNavigation
        renderAppSwitcher={hideIfOnMobile(AppSwitcherButton)}
        renderCreate={renderNewButton}
        primaryItems={primaryItems}
        renderProductHome={Home}
        renderProfile={enableSiteSwitcher ? ProfileAndWorkspaceSwitcherButton : AccountPopup}
        renderSearch={noWorkspaceExperience ? NoWorkspaceSearch : CrossProductSearch}
        renderNotifications={() => (
          <>
            <AddPeople />
            <NotificationsBell />
          </>
        )}
        renderHelp={ReleaseNotesAndHelp}
        renderSettings={SettingsPopup}
        moreLabel={
          isMobileWidth ? (
            <FormattedMessage
              id="townsquare.navigation.more.label-mobile"
              defaultMessage="Menu"
              description="Label to display Menu in the nav"
            />
          ) : (
            <div id="more-nav-button">
              <FormattedMessage
                id="townsquare.navigation.more.label"
                defaultMessage="More"
                description="Label to display More in the nav"
              />
            </div>
          )
        }
        label={intl.formatMessage({
          id: 'townsquare.navigation.chrome-navigation.label',
          description:
            'Describes the specific role of this navigation component for users viewing the page with a screen reader',
          defaultMessage: 'top',
        })}
        testId="atlas-navigation"
      />
      {createTeamModal}
      {inviteUserModal}
    </div>
  );
};

export { Navigation };
