import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { MainColumn, AsideColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { MetaActionsSkeleton, UpdateListSkeleton, FeedTitleSkeleton } from '../components/Skeletons';
import { SidebarContainer } from '../components/UpdatesFeeds/styles';

export const TeamUpdatesViewSkeleton = () => {
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const mainContent = (
    <>
      <FeedTitleSkeleton />
      <UpdateListSkeleton />
    </>
  );

  if (isNavRefreshEnabled) {
    return mainContent;
  }

  return (
    <>
      <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>{mainContent}</MainColumn>
      <AsideColumn>
        <SidebarContainer>
          <MetaActionsSkeleton />
          <SidebarSectionSkeleton rows={3} />
          <SidebarSectionSkeleton rows={3} />
        </SidebarContainer>
      </AsideColumn>
    </>
  );
};
