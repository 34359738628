import React from 'react';

import { OnlyMainColumnMaxWidth } from '@townsquare/home-layout/constants';
import { AsideColumn, MainColumn } from '@townsquare/responsive-grid';
import { SidebarSectionSkeleton } from '@townsquare/sidebar-section';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import {
  StatusSummaryWithHeaderSkeleton,
  TimespanNavigatorSkeleton,
  UpdateListSkeleton,
} from '../components/Skeletons';

import * as styled from './styles';

export const GoalUpdatesViewSkeleton = () => {
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const mainContent = (
    <styled.MainColumn>
      <TimespanNavigatorSkeleton />
      <StatusSummaryWithHeaderSkeleton />
      <UpdateListSkeleton />
    </styled.MainColumn>
  );

  if (isNavRefreshEnabled) {
    return mainContent;
  }

  return (
    <>
      <MainColumn maxWidthPx={OnlyMainColumnMaxWidth}>{mainContent}</MainColumn>
      <AsideColumn>
        <styled.SidebarContainer>
          <SidebarSectionSkeleton rows={3} />
        </styled.SidebarContainer>
      </AsideColumn>
    </>
  );
};
