import React, { PropsWithChildren, useMemo } from 'react';
import { graphql, usePreloadedQuery } from 'react-relay';

import { useRelayResource } from '@townsquare/relay-utils';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { capabilityProviderResource } from '../resource';
import { CapabilityProviderInternalProps, CapabilityProviderProps } from '../types';
import { checkUserHasCapabilities } from '../utils';

import { CapabilityProviderQuery } from './__generated__/CapabilityProviderQuery.graphql';

const CapabilityProviderInternal = (props: PropsWithChildren<CapabilityProviderInternalProps>) => {
  const data = usePreloadedQuery<CapabilityProviderQuery>(
    graphql`
      query CapabilityProviderQuery($workspaceContext: WorkspaceContextInput!, $isValidWorkspace: Boolean!) {
        userWorkspaceByContext(workspaceContext: $workspaceContext) @include(if: $isValidWorkspace) {
          workspace {
            ...utils_checkUserHasCapabilities
          }
        }
      }
    `,
    props.queryRef,
  );

  const hasCapabilities = useMemo(
    () => checkUserHasCapabilities(data.userWorkspaceByContext?.workspace, props.requirements),
    [data, props],
  );
  return <>{hasCapabilities ? props.children : null}</>;
};

export const CapabilityProvider = (props: PropsWithChildren<CapabilityProviderProps>) => {
  const capabilityRouterEnabled = useFeatureGate('atlas-enable-capability-router');
  const queryRef = useRelayResource(capabilityProviderResource);

  if (!queryRef || !capabilityRouterEnabled) {
    return <>{props.children}</>;
  }

  return <CapabilityProviderInternal queryRef={queryRef} {...props} />;
};
