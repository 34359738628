/**
 * @generated SignedSource<<62c5eb078e691d00ccc9017f3b43eb9e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraIntegrationLinkQuery$variables = {
  key: string;
};
export type JiraIntegrationLinkQuery$data = {
  readonly project: {
    readonly ari: string;
    readonly fusion: {
      readonly issue: {
        readonly href: string | null;
        readonly key: string | null;
      } | null;
      readonly issueAri: string;
      readonly synced: boolean;
    } | null;
    readonly id: string;
    readonly uuid: string;
  } | null;
};
export type JiraIntegrationLinkQuery = {
  response: JiraIntegrationLinkQuery$data;
  variables: JiraIntegrationLinkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": "project",
    "args": [
      {
        "kind": "Variable",
        "name": "key",
        "variableName": "key"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "projectByKey",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "id"
      },
      {
        "kind": "ScalarField",
        "name": "ari"
      },
      {
        "kind": "ScalarField",
        "name": "uuid"
      },
      {
        "concreteType": "FusionDetails",
        "kind": "LinkedField",
        "name": "fusion",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "synced"
          },
          {
            "kind": "ScalarField",
            "name": "issueAri"
          },
          {
            "concreteType": "GqlJiraIssue",
            "kind": "LinkedField",
            "name": "issue",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "href"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "JiraIntegrationLinkQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JiraIntegrationLinkQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b547118ed48738b39e081be37e7e9833",
    "id": null,
    "metadata": {},
    "name": "JiraIntegrationLinkQuery",
    "operationKind": "query",
    "text": "query JiraIntegrationLinkQuery(\n  $key: String!\n) {\n  project: projectByKey(key: $key) {\n    id\n    ari\n    uuid\n    fusion {\n      synced\n      issueAri\n      issue {\n        key\n        href\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f062b2d0995ec9a45fe2b0ef06cdabad";

export default node;
