import React from 'react';

import { useModalActions } from '@townsquare/modals';

import { CreateGoalModal } from './components';
import { PlatformCreateGoalModal, type PlatformCreateGoalModalOptions } from './components/PlatformCreateGoalModal';
import type { CreateGoalModalOptions } from './components/TownsquareGoalCreateModal';

export interface OpenModalAction {
  townsquare: { openCreateGoalModal: (options: CreateGoalModalOptions) => void };
  platform: { openCreateGoalModal: (options: PlatformCreateGoalModalOptions) => void };
}
export type ModalVersion = keyof OpenModalAction;

export const useCreateGoalModalVersion = (): ModalVersion => {
  /**
   * HOT-113609: Disabling the platform version as it is untested in team.a.c (tests are in home.a.c on AFM).
   *
   * I could remove all the dead code but it's a lot of effort and risk for a codebase that's gonna get deleted anyway,
   * so for not I'm just turning the feature off by force.
   *
   */
  return 'townsquare';
};

/**
 *
 * This uses the Townsquare version of the create goal modal, but we want to use the Platform version now
 */
export function useCreateGoalModal<T extends ModalVersion>(modalVersion: T): OpenModalAction[T] {
  const { openModal, closeModal } = useModalActions();

  const openCreateGoalModal =
    modalVersion === 'platform'
      ? (options: PlatformCreateGoalModalOptions) => {
          openModal('platform-create-goal-modal', {
            component: (
              <PlatformCreateGoalModal {...options} onClose={() => closeModal('platform-create-goal-modal')} />
            ),
          });
        }
      : (options: CreateGoalModalOptions) => {
          openModal('create-goal-modal', {
            component: <CreateGoalModal {...options} onClose={() => closeModal('create-goal-modal')} />,
          });
        };

  return {
    openCreateGoalModal,
  } as OpenModalAction[T];
}
