import { setStrictlyNecessaryCookie, getCookie } from '@atlassian/browser-storage-controls';
import { captureMessage } from '@sentry/react';
import { GraphQLError } from 'graphql';

import { getConfig, isEmbedOrIntegration, isInIframe } from '@townsquare/config';
import { PRODUCT_IDENTIFIER } from '@townsquare/config/constants';
import {
  FULL_PAGE_NOTIFICATIONS_PATH,
  GOAL_PATH_PREFIX,
  LABEL_PATH_PREFIX,
  NO_ACCESS_PATH,
  NO_WORKSPACE_PATHS,
  ONBOARDING_PATH,
  ONBOARDING_PRODUCT_OVERVIEW_PATH,
  PROJECT_PATH_PREFIX,
  TAG_FEED_PATH_PREFIX,
  YOUR_WORK_PATH,
} from '@townsquare/config/routes';
import { location, redirect } from '@townsquare/facade';
import {
  appendSearchStringToPath,
  appendToSearchString,
  createSearchParamsFromCurrentUrl,
  newSearchString,
} from '@townsquare/url-utils/search-params';

export { useBrowserStorageControls } from './use-browser-storage-controls';

const SAMPLE_DIGEST_EMAIL_ORIGIN_ID = 'sample-origin-id';

const config = getConfig();

export function returnAfterLogin() {
  if (isEmbedOrIntegration() || isInIframe()) {
    // Embeds and Jira Integration needs to handle this differently
    return;
  }
  redirect(getLogInUrl());
}

export const logout = () => {
  const currentUrl = new URL(location().href);
  currentUrl.searchParams.delete('cloudId');
  const currentUrlWithCloudIdParamRemoved = currentUrl.toString();
  redirect(`${config.atlassianAccountUrl}/logout?continue=${currentUrlWithCloudIdParamRemoved}&application=atlas`);
};

export function getLogInUrl(redirectUrl?: string) {
  const href = redirectUrl || location().href;
  return `${config.atlassianAccountUrl}/login?continue=${encodeURIComponent(href)}&application=atlas`;
}

export function getSelectAccountUrl(redirectUrl?: string) {
  const href = redirectUrl || location().href;
  return `${config.atlassianAccountUrl}/login/select-account?continue=${encodeURIComponent(href)}&application=atlas`;
}

export function isUsingDefaultUrl() {
  if (config.env === 'stg-east' || config.env === 'prod-east') {
    const currentUrl = location().href;
    const mainUrl = config.fullUrl;

    return currentUrl.startsWith(mainUrl);
  } else {
    return true;
  }
}

export enum StorageAccessState {
  Granted,
  UserMustGrant,
  Unknown,
}

const jiraIntegrationCookie = 'com.atlassian.atlas.jira-integration-cookie-check';

const checkCanSetCookieForJiraIntegration = (): boolean => {
  try {
    setStrictlyNecessaryCookie(jiraIntegrationCookie, 'any', {
      sameSite: 'None',
      secure: true,
      expires: new Date(0), // Thu, 01 Jan 1970 00:00:00 GMT
    });
    const cookieThatWasSet = getCookie(jiraIntegrationCookie);

    return !!cookieThatWasSet;
  } catch {
    return false;
  }
};

export async function requestStorageAccess(
  // We can only call document.requestStorageAccess in the context of a user interaction
  isUserRequest?: boolean,
): Promise<StorageAccessState> {
  if (checkCanSetCookieForJiraIntegration()) {
    // Do not need storage access if we can set cookies, because that's what the Storage Access API is an alternative for
    return StorageAccessState.Granted;
  }

  if (document.hasStorageAccess) {
    const hasAccess = await document.hasStorageAccess();
    if (!hasAccess) {
      // Check whether unpartitioned cookie access has been granted
      // to another same-site embed
      try {
        const permission = await navigator.permissions.query({
          // 'storage-access' only exists in some browsers,  but we cannot assume which do: https://developer.mozilla.org/en-US/docs/Web/API/Permissions
          name: 'storage-access' as PermissionName & 'storage-access',
        });

        if (permission.state === 'granted' || isUserRequest) {
          // If so, you can just call requestStorageAccess() without a user interaction,
          // and it will resolve automatically.
          await document.requestStorageAccess();
          return StorageAccessState.Granted;
        } else if (permission.state === 'prompt') {
          // Need to call requestStorageAccess() after a user interaction
          return StorageAccessState.UserMustGrant;
        } else if (permission.state === 'denied') {
          // User has denied unpartitioned cookie access
          captureMessage('User has denied unpartitioned cookie access', 'warning');
          return StorageAccessState.UserMustGrant;
        }
      } catch (error) {
        // Some browsers don't support this API, so we can't assume it will work
        return StorageAccessState.Granted;
      }
    }
  }
  return StorageAccessState.Granted;
}

/**
 * Make sure user is using the correct URL in stg/prod
 */
export function ensureCorrectUrl() {
  if (!isUsingDefaultUrl()) {
    redirect(`${config.fullUrl}${location().pathname}${location().search}`);
    throw new Error("This URL seems a bit wrong for Atlas. We'll take you to the right place :)");
  }
}

export function safeRedirect(continueUrl: string, fallbackUrl: string) {
  let parsedContinueUrl;
  try {
    parsedContinueUrl = continueUrl && new URL(decodeURIComponent(continueUrl));
  } catch (e) {
    captureMessage(`Failed to create new URL(): ${e}`, {
      level: 'info',
      extra: {
        continueUrl: btoa(continueUrl),
      },
    });
  }
  const parsedFullUrl = new URL(config.fullUrl);
  switch (true) {
    case parsedContinueUrl && parsedContinueUrl.pathname !== '/' && parsedContinueUrl.host === parsedFullUrl.host:
      redirect(continueUrl);
      break;
    default:
      redirect(fallbackUrl);
      break;
  }
}

export function redirectToHowPageFromSampleEmail(atlOrigin?: string) {
  if (atlOrigin && atlOrigin === SAMPLE_DIGEST_EMAIL_ORIGIN_ID) {
    redirect('/how');
  }
}

// Perform redirect from "/cloudId/:cloudId/teamcentral" to "/?cloudId=:cloudId"
export function handleSignUpCloudIdPathRedirect() {
  const loc = location();
  const paths = (loc.pathname || '').split('/');
  // Length is 4 because pathname has a leading slash. Thus paths[0] will contain an empty string entry
  if (paths.length >= 4 && paths[1] === 'cloudId' && paths[2] && (paths[3] === 'teamcentral' || paths[3] === 'atlas')) {
    const redirectPath = `/?${appendToSearchString(loc.search, { cloudId: paths[2] })}`;
    redirect(redirectPath);
  }
}

export function getWorkspaceJoinableParams(pathname: string, cloudId?: string | null) {
  const match = pathname.match(
    [
      `(${PROJECT_PATH_PREFIX}|${GOAL_PATH_PREFIX})/(?<key>[A-Z0-9]+-[0-9]+)`,
      `(${LABEL_PATH_PREFIX}|${TAG_FEED_PATH_PREFIX})/(?<tagId>[A-Za-z0-9=]+)`,
    ].join('|'),
  );

  if (match && match.groups) {
    return newSearchString({
      cloudId,
      ...match.groups,
    });
  }

  return newSearchString({
    cloudId,
  });
}

export const getOnboardingLinkWithContinueUrl = (cloudId?: string | null, continueUrl?: string) => {
  continueUrl ??= location().href;

  const ignoredContinuePaths = [ONBOARDING_PATH, YOUR_WORK_PATH, NO_ACCESS_PATH];

  return appendSearchStringToPath(
    ONBOARDING_PATH,
    newSearchString({
      cloudId,
      continue: ignoredContinuePaths.find(path => continueUrl?.includes(path)) ? undefined : continueUrl,
    }),
  );
};

export const getProductOverviewLinkWithContinueUrl = (cloudId?: string | null, continueUrl?: string) => {
  const nextUrl = continueUrl ?? location().href;

  return appendSearchStringToPath(
    ONBOARDING_PRODUCT_OVERVIEW_PATH,
    newSearchString({
      cloudId,
      continue: nextUrl.includes(ONBOARDING_PATH) ? undefined : nextUrl,
    }),
  );
};

export const getNoAccessLink = (cloudId?: string | null) => {
  return appendSearchStringToPath(
    NO_ACCESS_PATH,
    newSearchString({
      cloudId,
    }),
  );
};

const ANONYMOUS_ALLOWED_PATHS: string[] = [NO_ACCESS_PATH, ONBOARDING_PATH, ...NO_WORKSPACE_PATHS];

export const isAllowedAnonymousRoute = () => {
  const pathname = location().pathname;

  return ANONYMOUS_ALLOWED_PATHS.includes(pathname);
};

const ALLOWED_ANONYMOUS_AND_JOINABLE_PATHS: string[] = [YOUR_WORK_PATH, FULL_PAGE_NOTIFICATIONS_PATH];
/**
 * We don't want to redirect users to the adminhub join workspace
 * flow when they land in the Your Work page after redirecting from Start.a.c
 * This could appear like start is "broken", navigating away (inside Atlas) from
 * your-work will redirect to the adminhub join workspace flow.
 */
const isAllowedAnonymousAndJoinableRoute = (path?: string) => {
  const pathname = path || location().pathname;

  return ALLOWED_ANONYMOUS_AND_JOINABLE_PATHS.includes(pathname);
};

export const getNoWorkspaceLink = (cloudId?: string | null) => {
  if (isAllowedAnonymousRoute()) {
    return `${location().pathname}${location().search}`;
  }

  return appendSearchStringToPath(
    YOUR_WORK_PATH,
    newSearchString({
      cloudId,
    }),
  );
};

/**
 * This function returns the URL to the adminhub join workspace flow, when a user has access to an
 * Atlas workspace but has not joined.
 * There is another function - getUserAccessUrlForRedirectWithContinueUrl that includes some logic as to
 * whether they SHOULD be redirected there, but this function is just for getting the URL.
 */
export function getUserAccessUrlWithContinueUrl(cloudId: string, continueUrl?: string) {
  const ari = `ari:cloud:${PRODUCT_IDENTIFIER}::site/${cloudId}`;
  const href = `${config.fullUrl}${getOnboardingLinkWithContinueUrl(cloudId, continueUrl)}`;

  return `${config.atlassianAccountUrl}/join/user-access?resource=${ari}&continue=${encodeURIComponent(href)}`;
}

/**
 * This function wraps around getUserAccessUrlWithContinueUrl with some added logic to determine
 * whether the user should be redirected to the adminhub join workspace flow. In some cases, e.g.
 * when the user was expecting to land on start, we don't want to redirect them to the adminhub join.
 */
export function getUserAccessUrlForRedirectWithContinueUrl(cloudId: string, continueUrl?: string) {
  if (isAllowedAnonymousAndJoinableRoute(continueUrl)) {
    /**
     * Don't redirect to the adminhub join workspace flow when users land in the Your Work page
     * We need to keep the startRedirect param so we don't keep redirecting
     */
    const searchParams = createSearchParamsFromCurrentUrl();
    searchParams.set('startRedirect', 'true');
    searchParams.set('joinableWorkspace', cloudId);
    return `${location().pathname}?${searchParams.toString()}`;
  }

  return getUserAccessUrlWithContinueUrl(cloudId, continueUrl);
}

export function isUnauthorizedError(graphQLErrors?: ReadonlyArray<GraphQLError>) {
  if (graphQLErrors && graphQLErrors.length > 0) {
    const errText = graphQLErrors[0]?.message;

    if (
      errText?.includes('401 UNAUTHORIZED') ||
      errText?.includes('403 FORBIDDEN') ||
      errText?.includes('Access is denied')
    ) {
      return true;
    }
  }

  return false;
}
