import Button from '@atlaskit/button';
import { borderRadius } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import styled, { keyframes } from 'styled-components';

const kudosButtonAnimationTransformation = keyframes({
  '0%': {
    transform: 'translate(-80px, -50px)',
  },
  '100%': {
    transform: 'translate(90px, -70px)',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KudosBlobAnimation = styled.div({
  display: 'none',
  height: '150px',
  width: '150px',
  zIndex: -1,
  position: 'absolute',
  animationName: kudosButtonAnimationTransformation,
  animationIterationCount: 1,
  animationDuration: '3s',
  backgroundImage: `radial-gradient( circle, ${token('color.background.information.pressed')} 0%, ${token(
    'color.background.discovery.pressed',
  )} 25%, transparent 50% )`,
  overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const GiveKudosButton = styled(Button)`
  margin-left: ${token('space.150')};

  &:hover ${KudosBlobAnimation} {
    display: block;
  }

  button,
  a {
    clip-path: inset(0px 0px 0px 0px round ${borderRadius()}px);
  }
  overflow: hidden;
`;
