import { token } from '@atlaskit/tokens';
import styled from 'styled-components';

import { UserAvatarStyle } from '@townsquare/user-avatar';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Spinner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  width: '24px',
  height: '24px',
  background: token('color.background.neutral'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamAvatarWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ButtonWrapper = styled.div<{ isOpen?: boolean }>`
  opacity: ${props => (props.isOpen ? 1 : 0)};
  display: flex;
  // size should not vary with the size of the parent flex container or its sibling elements
  flex: none;

  ${TeamAvatarWrapper}:hover & {
    opacity: 1;
  }
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TeamAvatar = styled(UserAvatarStyle.UserAvatar)({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '> .ellipsis-tooltip-container': {
    flexShrink: 0,
  },
});
