import { DropdownItem } from '@atlaskit/dropdown-menu';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { getConfig } from '@townsquare/config';
import { useSort, useViewTql } from '@townsquare/directory-header/hooks';
import { useTownsquareUrl } from '@townsquare/hooks';
import { useResourceVariables } from '@townsquare/relay-utils';
import { createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { DEFAULT_GOAL_SORTS } from '../default-constants';
import { goalDirectoryTableResource } from '../resources/goal-directory-table';

import { DirectoryViewGoalQuery$variables } from './__generated__/DirectoryViewGoalQuery.graphql';
import { ExportGoalCsvQuery } from './__generated__/ExportGoalCsvQuery.graphql';

type ExportGoalCsvProps = {
  exportEntities: (url: string) => void;
};

type ExportGoalCsvInternalProps = {
  exportEntities: (url: string) => void;
  variables: DirectoryViewGoalQuery$variables;
};

const ExportGoalCsvInternal = ({ exportEntities, variables }: ExportGoalCsvInternalProps) => {
  const [{ UUID: workspaceUuid }] = useWorkspaceStore();
  const [viewTql] = useViewTql();
  const [sorts] = useSort(DEFAULT_GOAL_SORTS);
  const analytics = useAnalytics();
  const exportUrl = useTownsquareUrl(getConfig().exportGoalCsvByTqlPath);

  const data = useLazyLoadQuery<ExportGoalCsvQuery>(
    graphql`
      query ExportGoalCsvQuery(
        $tql: String
        $directoryViewUuid: UUID
        $workspaceUuid: UUID
        $containerId: String
        $after: String
        $first: Int
        $sorts: [GoalSortEnum]
      ) {
        goalTqlFullHierarchy(
          first: $first
          after: $after
          q: $tql
          directoryViewUuid: $directoryViewUuid
          workspaceUuid: $workspaceUuid
          containerId: $containerId
          sorts: $sorts
        ) {
          count
        }
      }
    `,
    variables,
    { fetchPolicy: 'store-only' },
  );

  const qs = createSearchParamsFromScratch({
    q: viewTql ?? '',
    workspaceUuid,
    sort: sorts.join(','),
  });

  const onClick = () => {
    void analytics.ui('exportGoalCsv', 'clicked');
    if (window.Intercom) {
      window.Intercom('trackEvent', 'exportGoalCsv', analytics.getTopLevelAttributes());
    }
    exportEntities(`${exportUrl}?${qs.toString()}`);
  };

  return (
    <DropdownItem isDisabled={(data.goalTqlFullHierarchy?.count ?? 0) > 1000} onClick={onClick}>
      <FormattedMessage
        id="townsquare.goals-directory.export-goal-csv.export-csv"
        description="Export CSV dropdown item"
        defaultMessage="Export CSV"
      />
    </DropdownItem>
  );
};

export const ExportGoalCsv = ({ exportEntities }: ExportGoalCsvProps) => {
  const variables = useResourceVariables(goalDirectoryTableResource);

  if (!variables) {
    return null;
  }

  return <ExportGoalCsvInternal exportEntities={exportEntities} variables={variables} />;
};
