import Cache from '@townsquare/cache';
import { resolveUser as resolveUserAGG } from '@townsquare/user-resolver';

import { UserTeamById } from './UserTeamQuery';
import type { ResolvedTeam } from './types';

export type { ResolvedTeam } from './types';

const userAndTeamCache = new Cache();
const CACHE_TTL = 59 * 60 * 1000;

type ResolvedUserPII = { name?: string; picture?: string };
export type ResolvedUser = { aaid: string; pii: ResolvedUserPII };

export const resolveUser = async (aaid: string): Promise<ResolvedUser> => {
  const key = `user:${aaid}`;

  if (userAndTeamCache.has(key)) {
    return userAndTeamCache.get(key);
  }

  return resolveUserAGG(aaid);
};

export const resolveTeam = async (teamId: string, cloudId: string): Promise<ResolvedTeam> => {
  const key = `team:${teamId}`;

  if (userAndTeamCache.has(key)) {
    return userAndTeamCache.get(key);
  }

  try {
    const team = await UserTeamById(teamId, cloudId);
    userAndTeamCache.set(key, team, CACHE_TTL);
    return team;
  } catch (error) {
    return {
      id: teamId,
      name: 'unknown',
    };
  }
};
